import React, {useState} from "react";
import {Container, Form, Button} from 'react-bootstrap';
import Alert from '../components/generic/Alert';
import API from "../modules/API";
import useFields from "../modules/hooks/useFields";
import HTTPMethod from "../enums/HTTPMethod";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const Login = ({connect}: AuthConnect) => {

    const DEFAULT: {login: Login, alert: AlertOption} = {
        login: {
            id: "",
            password: ""
        },
        alert: {
            variant: '',
            message: '',
            className: 'd-none'
        }
    }

    const [login, setLogin, handleChange] = useFields<Login>(DEFAULT.login);
    const [customAlert, setCustomAlert] = useState(DEFAULT.alert);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            setCustomAlert({
                variant: 'danger',
                message: "Impossible de valider le recaptcha. Essayer plus tard.",
                className: ''
            });
            return;
        }

        let recaptcha = await executeRecaptcha("login");

        const response = await API({
            url: "/authentication/login",
            method: HTTPMethod.POST,
            data: {...login, recaptcha: recaptcha}
        });

        switch (response.statusCode){
            case 200:
                await connect(response.data)
                break;
            default:
                setLogin({
                    id: login.id,
                    password: ""
                });
                setCustomAlert({
                    variant: 'danger',
                    message: response.data.error,
                    className: 'mb-0'
                });
                break;
        }
    }

    return (
        <div className="page py-5">
            <Container className="py-5">
                <Form onSubmit={handleSubmit} className="w-100 w-sm-75 w-lg-50 my-5 px-4 py-5 mx-auto bg-light">
                    <h1>Connexion</h1>
                    <Form.Group controlId="loginId" className="mt-3">
                        <Form.Label>Identifiant</Form.Label>
                        <Form.Control type="text" placeholder="Identifiant" value={login.id} name="id" required onChange={(e) => {handleChange(e); setCustomAlert(DEFAULT.alert)}}/>
                    </Form.Group>
                    <Form.Group controlId="loginPassword" className="mt-3">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control type="password" placeholder="Mot de passe" value={login.password} name="password" required onChange={(e) => {handleChange(e); setCustomAlert(DEFAULT.alert)}}/>
                    </Form.Group>
                    <div className="text-right my-3">
                        <Button variant="primary" type="submit">
                            Connexion
                        </Button>
                    </div>
                    <Alert option={customAlert}/>
                </Form>
            </Container>
        </div>
    );
}

export default Login;
