type pattern = {
    [key: string]: Intl.DateTimeFormatOptions
}

/**
 <p><b>d</b> => Day (01-31)</p>
 <p><b>D</b> => Day in letter (Monday, Tuesday, ...)</p>
 <p><b>m</b> => Month (01-12)</p>
 <p><b>M</b> => Month in letter (January, February, ...)</p>
 <p><b>y</b> => Year (00-99)</p>
 <p><b>Y</b> => Full Year (0000-9999)</p>

 <p><b>h</b> => Hour (0-23)</p>
 <p><b>H</b> => Hour (00-23)</p>
 <p><b>i</b> => Minute (0-59)</p>
 <p><b>I</b> => Minute (00-59)</p>
 <p><b>s</b> => Second (0-59)</p>
 <p><b>S</b> => Second (00-59)</p>
 **/
const DateParser = (date: Date, format: string): string => {
    let patterns: pattern = {
        d: {day: "2-digit"},
        D: {weekday: "long"},
        m: {month: "2-digit"},
        M: {month: "long"},
        y: {year: "2-digit"},
        Y: {year: "numeric"},

        h: {hour: "numeric"},
        H: {hour: "2-digit"},
        i: {minute: "numeric"},
        I: {minute: "2-digit"},
        s: {second: "numeric"},
        S: {second: "2-digit"}
    };

    let formattedDate = "";

    for(let i = 0; i < format.length; i++){

        let newPart = patterns[format[i]] === undefined ? format[i]:(new Intl.DateTimeFormat("fr-FR", patterns[format[i]]).format(date));

        if(format[i] === "H" && newPart.length > 2){
            newPart = newPart.slice(0, newPart.length-2)
        }

        if(format[i] === "I" && newPart.length !== 2){
            newPart = "0" + newPart;
        }

        formattedDate += newPart;
    }

    return formattedDate;
}

export default DateParser;