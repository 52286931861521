import React from "react";
import {NavLink, Link} from "react-router-dom";
import {Container, Navbar, Nav} from 'react-bootstrap';
import DarkMode from "./generic/DarkMode";

import PhaerisLogo from "../images/logo_header.png";
import PhaerisLogoMobile from "../images/logo_header_mobile.png";

const Header = ({token, disconnect}: APIToken & AuthDisconnect) => {

    return <header>
        <Navbar bg="light" expand="xl" className="border-bottom">
            <Container fluid={true} className="px-0">
                <Navbar.Brand>
                    <Link to="/">
                        <img
                            src={PhaerisLogo}
                            className="d-none d-sm-inline-block align-top"
                            alt="Phaeris"
                        />
                        <img
                            src={PhaerisLogoMobile}
                            className="d-inline-block d-sm-none align-top"
                            alt="Phaeris"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="menuHeader">
                    <Nav className="ml-auto">
                        {
                            token !== "" ? <>
                                <NavLink to="/board" className="nav-link px-3 underlineOnHover">TABLEAU DE BORD</NavLink>
                                <NavLink to="/stats" className="nav-link px-3 underlineOnHover">STATISTIQUES</NavLink>
                                <NavLink to="/search" className="nav-link px-3 underlineOnHover">RECHERCHE COMPTE</NavLink>
                                <NavLink to="/disconnect" className="nav-link px-3 underlineOnHover">DECONNEXION</NavLink>
                            </>:null
                        }
                        <DarkMode className="pl-3 py-2 d-flex align-items-center"/>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header>
}

export default Header;