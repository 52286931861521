import React, { useState } from "react";

const useField = <T>(initialValue : T): [
    T,
    React.Dispatch<React.SetStateAction<T>>,
    (e: any) => void,
] => {

    const [field, setField] = useState<T>(initialValue);

    const handleChange = (e: any): void => {
        if(typeof initialValue === "boolean"){
            setField(e.target.checked);
        }else{
            setField(e.target.value);
        }
    }

    return [field, setField, handleChange];
}

export default useField;
