import React, { useState } from "react";

const useFields = <T>(initialValue : T): [
    T,
    React.Dispatch<React.SetStateAction<T>>,
    (e: any) => void,
] => {

    const [fields, setFields] = useState<T>(initialValue);

    const handleChange = (e: any): void => {
        // @ts-ignore
        if(initialValue[e.target.name] instanceof Date){
            if(e.target.value !== "") {
                setFields((prev) => ({
                    ...prev,
                    [e.target.name]: new Date(e.target.value)
                }))
            }
        }
        // @ts-ignore
        else if(typeof initialValue[e.target.name] === "boolean"){
            setFields((prev) => ({
                ...prev,
                [e.target.name]: e.target.checked
            }))
        }else{
            setFields((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }

    return [fields, setFields, handleChange];
}

export default useFields;
