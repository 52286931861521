import {useEffect, useState} from "react";
import { Line } from 'react-chartjs-2';
import useFields from "../modules/hooks/useFields";
import API from "../modules/API";
import HTTPMethod from "../enums/HTTPMethod";
import DateParser from "../modules/DateParser";

const Board = ({token}: APIToken) => {

    const [labels, setLabels] = useState<string[]>([]);
    const [serverStats, setServerStats] = useFields<ServerStats[]>([]);
/*
    const [siteStats, setSiteStats] = useFields<{vip: number, ogrines: number}>({vip: 0, ogrines: 0});
*/

    useEffect(() => {
        if(token !== ""){
            (async () => {
                const response_server = await API({
                    url: "/stats/server/resume",
                    method: HTTPMethod.GET,
                    token: token
                });

                /*const response_site = await API({
                    url: "/stats/site/resume",
                    method: HTTPMethod.GET,
                    token: token
                });*/

                if(response_server.statusCode === 200) {
                    let labs: string[] = [];

                    response_server.data.forEach((el: ServerStats) => {
                        labs.push(DateParser(new Date(el.dateLog.timestamp*1000), "H:I"));
                    })

                    setLabels(labs);
                    setServerStats(response_server.data);
                }

                /*if(response_site.statusCode === 200) {
                    setSiteStats(response_site.data);
                }*/
            })()
        }
    }, [token])

    const getDiffMinMax = (array: any[], propertyName: string): number => {
        let ar = [...array].sort((a: any, b: any) => a[propertyName] < b[propertyName] ? -1:1);
        let min = ar[0] ? (ar[0][propertyName] ?? 0):0;
        let max = ar[ar.length-1] ? (ar[ar.length-1][propertyName] ?? 0):0;
        return max-min;
    }

    const lines = [
        {
            title: "Joueur connecté (24h)",
            propertyName: "connectedCharacters",
            dataLabel: "Joueur connecté",
            borderColor: "rgb(226, 77, 54)",
            backgroundColor: "rgba(141, 48, 34, 0.5)"
        },
        {
            title: "Total personnage (24h)",
            propertyName: "registeredCharacters",
            dataLabel: "Total personnage",
            borderColor: "rgb(50, 167, 199)",
            backgroundColor: "rgba(31, 104, 124, 0.5)"
        },
        {
            title: "Total compte jeu (24h)",
            propertyName: "registeredAccounts",
            dataLabel: "Total compte jeu",
            borderColor: "rgb(123, 199, 50)",
            backgroundColor: "rgba(77, 124, 31, 0.5)"
        },
        {
            title: "Total membre discord (24h)",
            propertyName: "discordMembers",
            dataLabel: "Total membre discord",
            borderColor: "rgb(103, 50, 199)",
            backgroundColor: "rgba(64, 31, 124, 0.5)"
        }
    ]

    return <div className="page py-3">

        {/*<div className="d-flex flex-wrap mx-3">
            <div className="bg-light px-3 py-2 mb-2 mr-2">
                <h4 className="text-primary">{siteStats.vip}</h4>
                <p>Nouveau VIP (24h)</p>
            </div>
            <div className="bg-light px-3 py-2 mb-2 mr-2">
                <h4 className="text-primary">{siteStats.ogrines}</h4>
                <p>Ogrines acheté (24h)</p>
            </div>
            <div className="bg-light px-3 py-2 mb-2 mr-2">
                <h4 className="text-primary">{getDiffMinMax(serverStats, "registeredCharacters")}</h4>
                <p>Personnage créé (24h)</p>
            </div>
            <div className="bg-light px-3 py-2 mb-2">
                <h4 className="text-primary">{getDiffMinMax(serverStats, "registeredAccounts")}</h4>
                <p>Compte créé (24h)</p>
            </div>
        </div>*/}

        <div className="d-flex flex-wrap justify-content-between px-2">
            {
                lines.map((resume, key) =>
                    <div key={key} className="w-100 w-lg-50 p-2 rounded">
                        <div className="bg-light p-4">
                            <Line
                                options={{
                                    responsive: true,
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: resume.title,
                                        },
                                    }
                                }}
                                data={{
                                    labels: labels,
                                    datasets: [
                                        {
                                            label: resume.dataLabel,
                                            borderColor: resume.borderColor,
                                            backgroundColor: resume.backgroundColor,
                                            data: serverStats.map((stat: any) => stat[resume.propertyName]),
                                        }
                                    ]
                                }}/>
                        </div>
                    </div>)
            }
        </div>
    </div>
}

export default Board;