import {useHistory} from "react-router-dom";
import React, {useEffect} from "react";
import {Spinner} from "react-bootstrap";

const Disconnect = ({disconnect}: AuthDisconnect) => {
    const history = useHistory();

    useEffect(() => {
        disconnect().then(() => {
            history.push({
                pathname: "/"
            })
        });
    })

    return (
        <div className="my-5 py-5 d-flex justify-content-center">
            <Spinner variant="primary" animation="border"/>
        </div>
    )
}

export default Disconnect;