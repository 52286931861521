import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import App from './App';

ReactDOM.render(
    <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}>
        <App />
    </GoogleReCaptchaProvider>,
    document.getElementById('root')
);
