import React, {HTMLAttributes, useEffect} from "react";
import {Form} from "react-bootstrap";
import useStorageItem from "../../modules/hooks/useStorageItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdjust} from "@fortawesome/free-solid-svg-icons";

const DarkMode = ({...rest}: HTMLAttributes<HTMLDivElement>) => {
    const [darkMode, setDarkMode] = useStorageItem("darkmode", false);

    const themeChange = (e: any): void => {
        setDarkMode(e.target.checked).then();
    }

    useEffect(() => {
        if(darkMode){
            document.getElementsByTagName("body")[0].classList.add("dark");
        }else{
            document.getElementsByTagName("body")[0].classList.remove("dark");
        }
    }, [darkMode])

    return <div {...rest} className={[rest.className,"theme-choice"].join(" ")} title="Activer/Désactiver le theme sombre">
        <Form.Check
            type="switch"
            id="theme-choice"
            checked={darkMode}
            onChange={themeChange}
        />
        <FontAwesomeIcon icon={faAdjust} className="theme-choice-icon" />
    </div>
}

export default DarkMode