import { useEffect, useState } from "react";

const useStorageItem = <T>(key: string, initialValue: T): [
    T,
    (value: T) => Promise<void>
] => {
    const [storedValue, setStoredValue] = useState<T>(initialValue);

    const setValue = async (value: T) => {
        try {
            localStorage.setItem(key, typeof value === "string" ? value : JSON.stringify(value));
            setStoredValue(value);
        }
        catch (e) {
            console.error(e);
        }
    };
    
    useEffect(() => {
        (async () => {
            const result = localStorage.getItem(key);
            if (result === null || (typeof initialValue === "object" && result.length < 3)) {
                await setValue(initialValue);
            } else {
                setStoredValue(typeof initialValue === "string" ? result:JSON.parse(result));
            }
        })();
    }, []);

    return [
        storedValue,
        setValue
    ];
}

export default useStorageItem;
