import useStorageItem from "./useStorageItem";

const useAuth = (): AuthResponse => {

    const DEFAULT: Authentication = {
        token: "",
        superAdmin: false
    }

    const [token, setToken] = useStorageItem<string>("token", DEFAULT.token);
    const [superAdmin, setSuperAdmin] = useStorageItem<boolean>("admin", DEFAULT.superAdmin);

    const connect = async (data: Authentication): Promise<void> => {
        await setToken(data.token);
        await setSuperAdmin(data.superAdmin);
    }

    const disconnect = async (): Promise<void> => {
        await setToken(DEFAULT.token);
        await setSuperAdmin(DEFAULT.superAdmin);
    }

    return {token, superAdmin, connect, disconnect};
}

export default useAuth;
