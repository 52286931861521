import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import './styles/App.scss';
import 'bootstrap';
import useAuth from "./modules/hooks/useAuth";

import Disconnect from "./pages/Disconnect";
import Login from "./pages/Login";
import Board from "./pages/Board";
import Header from "./components/Header";
import SearchAccount from "./pages/SearchAccount";
import StatsPeriod from "./pages/StatsPeriod";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const App = () => {
    const {token, superAdmin, connect, disconnect} = useAuth();

    return (
        <Router>
            <Header token={token} disconnect={disconnect}/>
            <Switch>
                <Route exact path='/board'>
                    {token === "" ? <Redirect to="/" />:<Board token={token}/>}
                </Route>

                <Route exact path='/stats'>
                    {token === "" || !superAdmin ? <Redirect to="/" />:<StatsPeriod token={token}/>}
                </Route>

                <Route exact path='/'>
                    {token === "" ? <Login connect={connect}/>:<Redirect to="/board" />}
                </Route>

                <Route exact path='/search'>
                    {token === "" ? <Redirect to="/" />:<SearchAccount token={token} superAdmin={superAdmin}/>}
                </Route>

                <Route exact path='/disconnect'>
                    <Disconnect disconnect={disconnect}/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
