import HTTPMethod from "../enums/HTTPMethod";

const API = async (options: APIOptions): Promise<APIResponse> => {

    let fetchOptions: RequestInit = {
        method: options.method,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if(options.token !== undefined){
        fetchOptions.headers = {
            ...fetchOptions.headers,
            'Authorization': 'Bearer ' + options.token
        }
    }

    if(options.data !== null && options.method !== HTTPMethod.GET){
        fetchOptions.body = JSON.stringify(options.data);
    }

    let fetchResult: APIResponse = {
        statusCode: 0,
        data: {}
    };

    try{
        const response: Response = await fetch(process.env.REACT_APP_BASE_URL + options.url, fetchOptions);
        fetchResult.statusCode = response.status;
        fetchResult.data = await response.json();
    } catch (e: any){
        console.log(e);
    }

    if(fetchResult.data.code === 401){
        alert("Votre session a expirée. Vous allez être déconnecté.");
        document.location.href = "/disconnect";
    }

    return fetchResult;
}

export default API;