import React from "react";
import {Alert as BootstrapAlert} from 'react-bootstrap';

const Alert = ({option}: {option: AlertOption}) => {
    if(option.type === undefined){
        switch (option.variant){
            case "danger":
                option.type = "Erreur";
                break;
            case "warning":
                option.type = "Attention";
                break;
            case "info":
                option.type = "Information";
                break;
            case "success":
                option.type = "Succès";
                break;
            default:
                option.type = "";
                break;
        }
    }

    return <BootstrapAlert variant={option.variant} className={option.className}>
        {option.type !== "" ? (<><b className={"text-"+option.variant}>{option.type}</b> - </>):""}
        {option.message}
    </BootstrapAlert>
}

export default Alert;