import {useEffect, useState} from "react";
import { Line } from 'react-chartjs-2';
import useFields from "../modules/hooks/useFields";
import API from "../modules/API";
import HTTPMethod from "../enums/HTTPMethod";
import DateParser from "../modules/DateParser";
import {ChartDataset, ScatterDataPoint} from "chart.js";
import {Container, Form} from "react-bootstrap";

const StatsPeriod = ({token}: APIToken) => {

    const [labels, setLabels] = useState<string[]>([]);
    const [serverStats, setServerStats] = useFields<ServerStatsPeriod[]>([]);
    const [lines, setLines] = useState<ChartDataset<"line", (number | ScatterDataPoint | null)[]>[]>([]);
    const [period, setPeriod, handleDateChange] = useFields({
        start: new Date(new Date().setTime(new Date().getTime() - (60 * 60 * 24 * 30 * 1000))),
        end: new Date()
    });

    useEffect(() => {
        if(token !== ""){
            (async () => {
                const response_server = await API({
                    url: "/stats/server/period/" + period.start.toISOString() + "/" + period.end.toISOString(),
                    method: HTTPMethod.GET,
                    token: token
                });

                if(response_server.statusCode === 200) {
                    let labs: string[] = [];

                    response_server.data.forEach((el: ServerStatsPeriod) => {
                        labs.push(DateParser(new Date(el.dateLog), "d-m-y H:I"));
                    })

                    setLabels(labs);
                    setServerStats(response_server.data);
                }
            })()
        }
    }, [token, period])

    useEffect(() => {
        setLines([
            {
                label: "AVG Joueur connecté",
                borderColor: "rgb(226, 77, 54)",
                backgroundColor: "rgba(141, 48, 34, 0.5)",
                data: serverStats.map((stat: any) => stat["avgConnectedCharacters"])
            },
            {
                label: "MIN Joueur connecté",
                borderColor: "rgb(226, 54, 208)",
                backgroundColor: "rgba(141, 34, 130, 0.5)",
                data: serverStats.map((stat: any) => stat["minConnectedCharacters"])
            },
            {
                label: "MAX Joueur connecté",
                borderColor: "rgb(226, 190, 54)",
                backgroundColor: "rgba(141, 119, 34, 0.5)",
                data: serverStats.map((stat: any) => stat["maxConnectedCharacters"])
            },
            {
                label: "Total personnage",
                borderColor: "rgb(50, 167, 199)",
                backgroundColor: "rgba(31, 104, 124, 0.5)",
                data: serverStats.map((stat: any) => stat["registeredCharacters"])
            },
            {
                label: "Total compte jeu",
                borderColor: "rgb(123, 199, 50)",
                backgroundColor: "rgba(77, 124, 31, 0.5)",
                data: serverStats.map((stat: any) => stat["registeredAccounts"])
            }
        ])
    }, [serverStats])

    return <div className="page py-3">
        <div className="d-flex">
            <Form className="w-25 pl-4">
                <Form.Group controlId="dateStart">
                    <Form.Label>Date de début</Form.Label>
                    <Form.Control type="datetime-local" name="start" value={DateParser(period.start, "Y-m-dTH:I")} onChange={handleDateChange} />
                </Form.Group>
                <Form.Group controlId="dateEnd">
                    <Form.Label>Date de début</Form.Label>
                    <Form.Control type="datetime-local" name="end" value={DateParser(period.end, "Y-m-dTH:I")} onChange={handleDateChange} />
                </Form.Group>
            </Form>

            <div className="w-75 stats-chart p-2 px-4 rounded">
                <div className="bg-light p-4">
                    <Line
                        options={{
                            responsive: true,
                            plugins: {
                                title: {
                                    display: true,
                                    text: "Relevé pour la période du "+ DateParser(period.start, "d-m-Y H:I") +" au "+ DateParser(period.end, "d-m-Y H:I") + " | Précision à l'heure",
                                },
                            },
                            interaction: {
                                mode: 'index'
                            }
                        }}
                        data={{
                            labels: labels,
                            datasets: lines
                        }}/>
                </div>
            </div>
        </div>
    </div>
}

export default StatsPeriod;