import React, {useState} from "react";
import useField from "../modules/hooks/useField";
import {Button, Form, Spinner} from "react-bootstrap";
import API from "../modules/API";
import HTTPMethod from "../enums/HTTPMethod";
import DataTable from "react-data-table-component";
import DateParser from "../modules/DateParser";
import AccountResultModal from "../components/AccountResultModal";

const SearchAccount = ({token, superAdmin}: APIToken & {superAdmin: boolean}) => {

    const cellStyleGreen = {
        style: {background: 'rgba(75,137,121,0.2)'}
    }
    const cellStyleBlue = {
        style: {background: 'rgba(75,93,137,0.2)'}
    }
    const cellStyleRed = {
        style: {background: 'rgba(137,75,75,0.2)'}
    }
    
    const [searchPseudo, setSearchPseudo, handleChangeSearchPseudo] = useField("");
    const [searchId, setSearchId, handleChangeSearchId] = useField("");
    const [searchIp, setSearchIp, handleChangeSearchIp] = useField("");
    const [searchClientKey, setSearchClientKey, handleChangeSearchClientKey] = useField("");
    const [waitSearch, setWaitSearch] = useState(false);
    const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [selectedSearch, setSelectedSearch] = useState<SearchResult>({
        accountBanEnd: {timestamp: 0},
        accountBanReason: "",
        accountClientKey: "",
        accountCreationDate: {timestamp: 0},
        accountId: 0,
        accountIp: "",
        accountIsBanned: false,
        accountIsJailed: false,
        accountLastConnection: {timestamp: 0},
        accountLogin: "",
        accountNickname: "",
        accountTokens: 0,
        characterBreed: 0,
        characterCreationDate: {timestamp: 0},
        characterId: 0,
        characterNickname: "",
        characterSex: 0,
        webaccountEmail: "",
        webaccountEmailVerified: false,
        webaccountId: 0,
        webaccountNickname: "",
        webaccountSubscriptionEnd: {timestamp: 0},
        webaccountSubscriptionType: 0,
        webaccountTokens: 0
    })

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if(searchPseudo === "" && (searchId === "" || searchId === "0") && searchIp === "" && searchClientKey === ""){
            alert("Au moins un critère de recherche doit être renseigné");
            return;
        }

        setWaitSearch(true);

        const response = await API({
            url: "/search"+"?pseudo="+searchPseudo+"&id="+searchId+"&ip="+searchIp+"&clientkey="+searchClientKey,
            method: HTTPMethod.GET,
            token: token
        })

        if(response.statusCode === 200){
            setSearchResult(response.data);
        }else{
            alert(response.data.error);
        }

        setWaitSearch(false);
    }
    
    const HeadComponent = ({row}: {row: SearchResult}) =>
        row.characterBreed === undefined || row.characterSex === undefined || row.characterBreed === 0 || row.characterSex === 0 ? <></>
            :<img className="w-100" alt={row.characterBreed+""+row.characterSex} src={require("../images/heads/"+row.characterBreed+""+row.characterSex+".png")} />

    
    const boolType = (value: boolean) => value ? "oui":"non"
    const dateType = (value: {timestamp: number}|number|undefined|null, format: string = "d/m/y") => {
        if(value){
            return DateParser(new Date((typeof value === "number" ? value:value.timestamp)*1000), format);
        }

        return "";
    }

    return <div className="page p-2">
        <Form onSubmit={handleSubmit} className="w-100 px-3 py-4 bg-light row m-0 align-items-end">
            <Form.Group controlId="name" className="col-2 my-0">
                <Form.Label>Nom de compte</Form.Label>
                <Form.Control type="text" value={searchPseudo} onChange={handleChangeSearchPseudo}/>
            </Form.Group>
            <Form.Group controlId="id" className="col-2 my-0">
                <Form.Label>ID de compte</Form.Label>
                <Form.Control type="number" value={searchId} onChange={handleChangeSearchId}/>
            </Form.Group>
            <Form.Group controlId="ip" className="col-2 my-0">
                <Form.Label>IP</Form.Label>
                <Form.Control type="text" value={searchIp} onChange={handleChangeSearchIp}/>
            </Form.Group>
            <Form.Group controlId="clientkey" className="col-2 my-0">
                <Form.Label>Client Key</Form.Label>
                <Form.Control type="text" value={searchClientKey} onChange={handleChangeSearchClientKey}/>
            </Form.Group>

            <Button type="submit" variant="primary" className="col-1 my-0">Rechercher</Button>
            {
                waitSearch ? <Spinner animation="border" variant="primary" className="ml-2 mb-1" />:""
            }
        </Form>

        <div className="mt-2 border">
            <DataTable
                columns={[
                    {...cellStyleGreen, name: "id", selector: (row: SearchResult) => row.webaccountId, sortable: true, width: "62px"},
                    {...cellStyleGreen, name: "Nom", selector: (row: SearchResult) => row.webaccountNickname, sortable: true},
                    {...cellStyleGreen, name: "Tokens", selector: (row: SearchResult) => row.webaccountTokens, sortable: true, width: "90px"},
                    {...cellStyleGreen, name: "VIP", selector: (row: SearchResult) => row.webaccountSubscriptionEnd?.timestamp ?? "", format: (row: SearchResult) => dateType(row.webaccountSubscriptionEnd), sortable: true},
                    {...cellStyleGreen, name: "VIP", selector: (row: SearchResult) => row.webaccountSubscriptionType, sortable: true, width: "70px"},
                    {...cellStyleGreen, name: "Vérifié", selector: (row: SearchResult) => boolType(row.webaccountEmailVerified), sortable: true, width: "85px"},

                    {...cellStyleBlue, name: "id", selector: (row: SearchResult) => row.accountId, sortable: true, width: "62px"},
                    {...cellStyleBlue, name: "Login", selector: (row: SearchResult) => row.accountLogin, sortable: true},
                    {...cellStyleBlue, name: "Nom", selector: (row: SearchResult) => row.accountNickname, sortable: true},
                    {...cellStyleBlue, name: "Prison", selector: (row: SearchResult) => boolType(row.accountIsJailed), sortable: true, width: "85px"},
                    {...cellStyleBlue, name: "Banni", selector: (row: SearchResult) => boolType(row.accountIsBanned), sortable: true, width: "85px"},
                    {...cellStyleBlue, name: "Tokens", selector: (row: SearchResult) => row.accountTokens, sortable: true, width: "90px"},
                    {...cellStyleBlue, name: "IP", selector: (row: SearchResult) => row.accountIp ?? "", sortable: true},
                    {...cellStyleBlue, name: "ClientKey", selector: (row: SearchResult) => row.accountClientKey ?? "", sortable: true, width: "190px"},

                    {...cellStyleRed, name: "", cell: (row: SearchResult) => <HeadComponent row={row}/>, minWidth: "50px", maxWidth: "50px"},
                    {...cellStyleRed, name: "id", selector: (row: SearchResult) => row.characterId ?? "", sortable: true, width: "80px"},
                    {...cellStyleRed, name: "Nom", selector: (row: SearchResult) => row.characterNickname ?? "", cell: (row:SearchResult) => <a target="_blank" href={"https://phaeris.org/character/" + row.characterId}>{row.characterNickname}</a>, sortable: true},
                    {...cellStyleRed, name: "Création", selector: (row: SearchResult) => row.characterCreationDate?.timestamp ?? "", format: (row) => dateType(row.characterCreationDate), sortable: true}
                ]}
                data={searchResult}
                dense={true}
                striped={true}
                highlightOnHover={true}
                pagination={true}
                responsive={true}
                paginationComponentOptions={{
                    rowsPerPageText: 'Ligne par page:',
                    rangeSeparatorText: 'de'
                }}
                onRowDoubleClicked={(row) => {if(superAdmin){setSelectedSearch(row); setShowAccountModal(true)}}}
                paginationPerPage={30}
                progressComponent={<div className="w-100 py-5 d-flex justify-content-center bg-light text-secondary"><Spinner variant="primary" animation="border"/></div>}
                noDataComponent={<div className="text-center w-100 py-5 bg-light text-secondary">Rien</div>}
            />

        </div>

        <AccountResultModal open={showAccountModal} onClose={() => setShowAccountModal(false)} token={token} account={selectedSearch} />
    </div>
}

export default SearchAccount;