import React, {useEffect} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import useFields from "../modules/hooks/useFields";
import DateParser from "../modules/DateParser";
import API from "../modules/API";
import HTTPMethod from "../enums/HTTPMethod";

const AccountResultModal = ({open, onClose, token, account}: ModalOption & APIToken & {account: SearchResult}) => {

    const cellStyleGreen = {
        style: {background: 'rgba(75,137,121,0.2)'}
    }
    const cellStyleBlue = {
        style: {background: 'rgba(75,93,137,0.2)'}
    }
    const cellStyleRed = {
        style: {background: 'rgba(137,75,75,0.2)'}
    }

    const [editAccount, setEditAccount, handleEditAccount] = useFields({...account});

    useEffect(() => {
        setEditAccount({...account});
    }, [account]);

    const dateType = (value: {timestamp: number}|number|undefined|null, format: string = "Y-m-d") => {
        if(value){
            return DateParser(new Date((typeof value === "number" ? value:value.timestamp)*1000), format);
        }

        return "";
    }

    const handleChangeDate = (e: any) => {
        setEditAccount((prev) => ({
            ...prev,
            [e.target.name]: {
                timestamp: e.target.value !== "" ? (new Date(e.target.value).getTime() / 1000):0
            }
        }))
    }

    const editSubmit = async (e: any) => {
        e.preventDefault();

        const response = await API({
            url: "/search/save",
            method: HTTPMethod.PUT,
            token: token,
            data: editAccount
        })

        if(response.statusCode === 204){
            alert("Sauvegardé");
        }else{
            alert(response.data.error);
        }
    }

    return <Modal className={"modal-xxl"} show={open} onHide={onClose} centered>
        <Modal.Body className="py-5">
            <h2 className="text-center">Informations</h2>

            <Form className="mt-5" onSubmit={editSubmit}>

                <div className="d-flex flex-column flex-xl-row justify-content-between">

                    <fieldset className="w-100 w-xl-30 py-2 px-4" {...cellStyleGreen}>
                        <legend>Compte site</legend>
                        <Form.Group as={Row} controlId="webaccountId">
                            <Form.Label column sm="4">ID</Form.Label>
                            <Col sm="8">
                                <Form.Control type="number" disabled value={editAccount.webaccountId}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="webaccountEmail">
                            <Form.Label column sm="4">Email</Form.Label>
                            <Col sm="8">
                                <Form.Control type="email" name="webaccountEmail" value={editAccount.webaccountEmail} onChange={handleEditAccount}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="webaccountNickname">
                            <Form.Label column sm="4">Nom</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" name="webaccountNickname" value={editAccount.webaccountNickname} onChange={handleEditAccount}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="webaccountTokens">
                            <Form.Label column sm="4">Tokens</Form.Label>
                            <Col sm="8">
                                <Form.Control type="number" min={0} name="webaccountTokens" value={editAccount.webaccountTokens} onChange={handleEditAccount}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="webaccountSubscriptionEnd">
                            <Form.Label column sm="4">VIP</Form.Label>
                            <Col sm="8">
                                <Form.Control type="datetime-local" name="webaccountSubscriptionEnd" disabled value={dateType(editAccount.webaccountSubscriptionEnd, "Y-m-dTH:I")}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="webaccountSubscriptionType">
                            <Form.Label column sm="4">VIP</Form.Label>
                            <Col sm="8">
                                <Form.Control type="number" min={0} name="webaccountSubscriptionType" disabled value={editAccount.webaccountSubscriptionType}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="webaccountEmailVerified">
                            <Form.Label column sm="4">Vérifié</Form.Label>
                            <Col sm="8">
                                <Form.Check
                                    type="switch"
                                    id="webaccountEmailVerifiedSwitch"
                                    className="mt-1"
                                    name="webaccountEmailVerified"
                                    checked={editAccount.webaccountEmailVerified}
                                    onChange={handleEditAccount}
                                />
                            </Col>
                        </Form.Group>
                    </fieldset>

                    <fieldset className="w-100 w-xl-30 py-2 px-4" {...cellStyleBlue}>
                        <legend>Compte jeu</legend>

                        <Form.Group as={Row} controlId="accountId">
                            <Form.Label column sm="4">ID</Form.Label>
                            <Col sm="8">
                                <Form.Control type="number" disabled name="accountId" value={editAccount.accountId}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountLogin">
                            <Form.Label column sm="4">Login</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" name="accountLogin" value={editAccount.accountLogin} onChange={handleEditAccount}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountNickname">
                            <Form.Label column sm="4">Nom</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" name="accountNickname" value={editAccount.accountNickname} onChange={handleEditAccount}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountCreationDate">
                            <Form.Label column sm="4">Création</Form.Label>
                            <Col sm="8">
                                <Form.Control type="date" name="accountCreationDate" disabled value={dateType(editAccount.accountCreationDate)}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountLastConnection">
                            <Form.Label column sm="4">Connexion</Form.Label>
                            <Col sm="8">
                                <Form.Control type="datetime-local" name="accountLastConnection" disabled value={dateType(editAccount.accountLastConnection, "Y-m-dTH:I")}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountIsJailed">
                            <Form.Label column sm="4">Prison</Form.Label>
                            <Col sm="8">
                                <Form.Check
                                    type="switch"
                                    id="accountIsJailedSwitch"
                                    className="mt-1"
                                    name="accountIsJailed"
                                    checked={editAccount.accountIsJailed}
                                    onChange={handleEditAccount}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountIsBanned">
                            <Form.Label column sm="4">Banni</Form.Label>
                            <Col sm="8">
                                <Form.Check
                                    type="switch"
                                    id="accountIsBannedSwitch"
                                    className="mt-1"
                                    name="accountIsBanned"
                                    checked={editAccount.accountIsBanned}
                                    onChange={handleEditAccount}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountBanReason">
                            <Form.Label column sm="4">Ban Raison</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" name="accountBanReason" value={editAccount.accountBanReason} onChange={handleEditAccount}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountBanEnd">
                            <Form.Label column sm="4">Ban Fin</Form.Label>
                            <Col sm="8">
                                <Form.Control type="datetime-local" name="accountBanEnd" value={dateType(editAccount.accountBanEnd, "Y-m-dTH:I")} onChange={handleChangeDate}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountIp">
                            <Form.Label column sm="4">IP</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" name="accountIp" disabled value={editAccount.accountIp}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountClientKey">
                            <Form.Label column sm="4">Client Key</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" name="accountClientKey" disabled value={editAccount.accountClientKey}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="accountTokens">
                            <Form.Label column sm="4">Tokens</Form.Label>
                            <Col sm="8">
                                <Form.Control type="number" min={0} name="accountTokens" value={editAccount.accountTokens} onChange={handleEditAccount}/>
                            </Col>
                        </Form.Group>
                    </fieldset>

                    <fieldset className="w-100 w-xl-30 py-2 px-4" {...cellStyleRed}>
                        <legend>Personnage</legend>

                        <Form.Group as={Row} controlId="characterId">
                            <Form.Label column sm="4">ID</Form.Label>
                            <Col sm="8">
                                <Form.Control type="number" disabled name="characterId" value={editAccount.characterId ?? ""}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="characterNickname">
                            <Form.Label column sm="4">Nom</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" name="characterNickname" value={editAccount.characterNickname ?? ""} onChange={handleEditAccount}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="characterCreationDate">
                            <Form.Label column sm="4">Création</Form.Label>
                            <Col sm="8">
                                <Form.Control type="date" name="characterCreationDate" disabled value={dateType(editAccount.characterCreationDate)}/>
                            </Col>
                        </Form.Group>

                        <div className="text-right">
                            <a className="btn btn-outline-primary" target="_blank" href={"https://phaeris.org/character/" + editAccount.characterId}>
                                Page personnage
                            </a>
                        </div>

                    </fieldset>
                </div>

                <div className="d-flex justify-content-end mt-4">
                    <Button type="button" variant="secondary" onClick={onClose}>
                        Fermer
                    </Button>
                    <Button className="ml-2" type="submit" variant="primary">
                        Sauvegarder
                    </Button>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
}

export default AccountResultModal;